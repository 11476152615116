import { Link } from 'gatsby';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Copyright from './Copyright';
import InternalLinkOrig from './InternalLink';

const InternalLink = styled(InternalLinkOrig)`
  padding: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 7rem;
  line-height: 2;
  background: url(${props => props.background});
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
`;

const internalLinkStyle = { margin: '0 1rem' };

export default ({ background }) => (
  <ThemeProvider theme={{ black: false }}>
    <Wrapper background={background}>
      <Link to="/contact">
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Contact
        </InternalLink>
      </Link>
      <Link to="/diary">
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Diary
        </InternalLink>
      </Link>
      <Link to="/jobs">
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Jobs
        </InternalLink>
      </Link>
      <Link to="/newsletter">
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Newsletter
        </InternalLink>
      </Link>
      <Link to="/imprint">
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Imprint
        </InternalLink>
      </Link>
      <Link to="/privacy">
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Privacy Policy
        </InternalLink>
      </Link>
      <a
        href="https://www.instagram.com/florafaunavisions/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Instagram
        </InternalLink>
      </a>
      <a
        href="https://vimeo.com/ffvberlin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Vimeo
        </InternalLink>
      </a>
      <a
        href="https://www.linkedin.com/company/flora&faunavisions/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          LinkedIn
        </InternalLink>
      </a>
      <a
        href="https://www.facebook.com/florafaunavisionsgmbh/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InternalLink small style={internalLinkStyle} noUnderline noHover>
          Facebook
        </InternalLink>
      </a>
      <Copyright />
    </Wrapper>
  </ThemeProvider>
);
